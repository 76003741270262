<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="字典名称"
        >
          <a-input
            placeholder="请输入字典名称"
            v-decorator.trim="['dictName', validatorRules.dictName]"
          />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="字典编码"
        >
          <a-input
            placeholder="请输入字典编码"
            v-decorator.trim="['dictCode', validatorRules.dictCode]"
          />
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="描述">
          <a-input v-decorator="['description']" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick';
import { getAction, putAction, postAction } from '@/api/manage';

export default {
  name: 'DictModal',
  data() {
    return {
      url: {
        add: '/api/sys/dict/dictadd',
        edit: '/api/sys/dict/dictedit',
        check: '/api/sys/duplicate/check',
      },
      value: 1,
      title: '操作',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        dictName: { rules: [{ required: true, message: '请输入字典名称!' }] },
        dictCode: {
          rules: [{ required: true, message: '请输入字典编码!' }, { validator: this.validateDictCode }],
        },
      },
    };
  },
  created() {},
  methods: {
    validateDictCode(rule, value, callback) {
      // 重复校验
      const params = {
        tableName: 'sys_dict',
        fieldName: 'dict_code',
        fieldVal: value,
        dataId: this.model.id,
      };
      getAction(this.url.check, params).then((res) => {
        console.log('duplicateCheck');
        if (res !== undefined) {
          callback();
        } else {
          callback(res.message);
        }
      });
    },
    handleChange(value) {
      this.model.status = value;
    },
    add() {
      this.edit({});
    },
    edit(record) {
      if (record.id) {
        this.visiblekey = true;
      } else {
        this.visiblekey = false;
      }
      this.form.resetFields();
      this.model = { ...record };
      this.visible = true;
      this.$nextTick(() => {
        this.form.setFieldsValue(
          pick(this.model, 'dictName', 'dictCode', 'description')
        );
      });
    },
    // 确定
    handleOk() {
      const that = this;
      console.log('validateFields');
      // 触发表单验证
      this.form.validateFields((err, values) => {
        console.log('validateFields');
        if (!err) {
          // that.confirmLoading = true;
          values.dictName = (values.dictName || '').trim();
          values.dictCode = (values.dictCode || '').trim();
          values.description = (values.description || '').trim();
          const formData = Object.assign(this.model, values);
          if (!this.model.id) {
            postAction(this.url.add, formData)
              .then((res) => {
                if (res !== undefined) {
                  that.$emit('ok');
                } else {
                  that.$message.warning(res.message);
                }
              })
              .finally(() => {
                // that.confirmLoading = false;
                that.close();
              });
          } else {
            putAction(this.url.edit, formData)
              .then((res) => {
                if (res !== undefined) {
                  that.$emit('ok');
                } else {
                  that.$message.warning(res.message);
                }
              })
              .finally(() => {
                // that.confirmLoading = false;
                that.close();
              });
          }
        }
      });
    },
    // 关闭
    handleCancel() {
      this.close();
    },
    close() {
      this.$emit('close');
      this.visible = false;
    },
  },
};
</script>
