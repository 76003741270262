<template>
  <a-modal
    :width="modalWidth"
    :style="modalStyle"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleCancel">
    <template slot="footer">
      <a-button @click="handleCancel">关闭</a-button>
    </template>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :loading="loading"
      :dataSource="dataSource"
      :pagination="false">
      <span slot="action" slot-scope="text, record">
        <a @click="handleBack(record.id)"><a-icon type="redo"/>字典取回</a>
        <a-divider type="vertical"/>
        <a @click="handleDelete(record.id)"><a-icon type="scissor"/>彻底删除</a>
      </span>
    </a-table>

  </a-modal>

</template>

<script>
import { getAction, deleteAction, putAction } from '@/api/manage';

export default {
  name: 'DictDeleteList',
  data() {
    return {
      modalWidth: '90%',
      modalStyle: { top: '20px' },
      title: '操作',
      visible: false,
      loading: false,
      dataSource: [],
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 120,
          align: 'center',
          customRender(t, r, index) {
            return parseInt(index) + 1;
          }
        },
        {
          title: '字典名称',
          align: 'left',
          dataIndex: 'dictName'
        },
        {
          title: '字典编号',
          align: 'left',
          dataIndex: 'dictCode'
        },
        {
          title: '描述',
          align: 'left',
          dataIndex: 'description'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    };
  },

  methods: {
    handleCancel() {
      this.visible = false;
      // 回收站字典列表刷新
      this.$emit('refresh');
    },
    show() {
      this.visible = true;
      this.loadData();
    },
    loadData() {
      this.loading = true;
      getAction('/sys/dict/deleteList').then((res) => {
        this.loading = false;
        if (res.success) {
          this.dataSource = res.result;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    handleBack(id) {
      putAction(`/sys/dict/back/${id}`).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.loadData();
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    handleDelete(id) {
      this.$confirm({
        title: '彻底删除字典',
        content: (<div>
            <p>您确定要彻底删除这个字典项吗？</p>
            <p style="color:red;">注意：彻底删除后将无法恢复，请谨慎操作！</p>
            </div>),
        centered: false,
        onOk: () => {
          const that = this;
          deleteAction(`/sys/dict/deletePhysic/${id}`).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.loadData();
            } else {
              that.$message.warning(res.message);
            }
          });
        },
      });
    }

  }
};
</script>

<style scoped>

/*列表上方操作按钮区域*/
.ant-card-body .table-operator {
  margin-bottom: 8px;
}
/** Button按钮间距 */
.table-operator .ant-btn {
  margin: 0 8px 8px 0;
}
.table-operator .ant-btn-group .ant-btn {
  margin: 0;
}

.table-operator .ant-btn-group .ant-btn:last-child {
  margin: 0 8px 8px 0;
}
/*列表td的padding设置 可以控制列表大小*/
.ant-table-tbody .ant-table-row td {
  padding-top: 15px;
  padding-bottom: 15px;
}

/*列表页面弹出modal*/
.ant-modal-cust-warp {
  height: 100%
}

/*弹出modal Y轴滚动条*/
.ant-modal-cust-warp .ant-modal-body {
  height: calc(100% - 110px) !important;
  overflow-y: auto
}

/*弹出modal 先有content后有body 故滚动条控制在body上*/
.ant-modal-cust-warp .ant-modal-content {
  height: 90% !important;
  overflow-y: hidden
}
/*列表中有图片的加这个样式 参考用户管理*/
.anty-img-wrap {
  height: 25px;
  position: relative;
}
.anty-img-wrap > img {
  max-height: 100%;
}
/*列表中范围查询样式*/
.query-group-cust{width: calc(50% - 10px)}
.query-group-split-cust:before{content:"~";width: 20px;display: inline-block;text-align: center}

/*erp风格子表外框padding设置*/
.ant-card-wider-padding.cust-erp-sub-tab>.ant-card-body{padding:5px 12px}

/* 内嵌子表背景颜色 */
.j-inner-table-wrapper /deep/ .ant-table-expanded-row .ant-table-wrapper .ant-table-tbody .ant-table-row {
  background-color: #FFFFFF;
}

/**隐藏样式-modal确定按钮 */
.jee-hidden{display: none}
</style>
