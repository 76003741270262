<template>
  <a-card :bordered="false">

    <!-- 左侧面板 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="5" :sm="8">
            <a-form-item label="字典名称" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
              <a-input placeholder="请输入字典名称" v-model="queryParam.dictName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="8">
            <a-form-item label="字典编号" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
              <a-input placeholder="请输入字典编号" v-model="queryParam.dictCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <div class="table-operator" style="border-top: 5px">
        <a-button @click="handleAdd" type="primary" icon="plus">添加</a-button>
      </div>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange">
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">
            <a-icon type="edit"/>
            编辑
          </a>
          <a-divider type="vertical"/>
          <a @click="editDictItem(record)"><a-icon type="setting"/> 字典配置</a>
          <a-divider type="vertical"/>
          <a-popconfirm title="确定删除吗?" @confirm="() =>handleDelete(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </a-table>

    </div>
    <dict-modal ref="modalForm" @ok="modalFormOk"></dict-modal>  <!-- 字典类型 -->
    <dict-item-list ref="dictItemList"></dict-item-list>
    <dict-delete-list ref="dictDeleteList" @refresh="() =>loadData()"></dict-delete-list>
  </a-card>
</template>

<script>
import { filterObj } from '@/libs/util';
import { JeecgListMixin } from '@/mixin/JeecgListMixin';
import DictModal from './modules/DictModal';
import DictItemList from './DictItemList';
import DictDeleteList from './DictDeleteList';
import { getAction } from '@/api/manage';

export default {
  name: 'DictList',
  mixins: [JeecgListMixin],
  components: { DictModal, DictItemList, DictDeleteList },
  data() {
    return {
      description: '这是数据字典页面',
      visible: false,
      // 查询条件
      queryParam: {
        dictCode: '',
        dictName: '',
      },
      // 表头
      columns: [
        {
          title: '#',
          dataIndex: '',
          key: 'rowIndex',
          width: 120,
          align: 'center',
          customRender(t, r, index) {
            return parseInt(index) + 1;
          }
        },
        {
          title: '字典名称',
          align: 'left',
          dataIndex: 'dictName',
        },
        {
          title: '字典编号',
          align: 'left',
          dataIndex: 'dictCode',
        },
        {
          title: '描述',
          align: 'left',
          dataIndex: 'description',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        }
      ],
      dict: '',
      labelCol: {
        xs: { span: 5 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 8 },
        sm: { span: 19 },
      },
      url: {
        list: '/api/sys/dict/dictlist',
        delete: '/api/sys/dict/delete',
        refleshCache: '/api/sys/dict/refleshCache',
        queryAllDictItems: '/api/sys/dict/queryAllDictItems',
      },
    };
  },
  computed: {

  },
  methods: {
    getQueryParams() {
      const param = { ...this.queryParam, ...this.isorter };
      param.field = this.getQueryField();
      param.pageNo = this.ipagination.current;
      param.pageSize = this.ipagination.pageSize;
      if (this.superQueryParams) {
        param.superQueryParams = encodeURI(this.superQueryParams);
        param.superQueryMatchType = this.superQueryMatchType;
      }
      return filterObj(param);
    },
    // 取消选择
    cancelDict() {
      this.dict = '';
      this.visible = false;
      this.loadData();
    },
    // 编辑字典数据
    editDictItem(record) {
      this.$refs.dictItemList.edit(record);
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      const that = this;
      that.queryParam.dictName = '';
      that.queryParam.dictCode = '';
      that.loadData(this.ipagination.current);
    },
    openDeleteList() {
      this.$refs.dictDeleteList.show();
    },
    refleshCache() {
      getAction(this.url.refleshCache).then((res) => {
        if (res.success) {
          // 重新加载缓存
          getAction(this.url.queryAllDictItems).then((res) => {

          });
          this.$message.success('刷新缓存完成！');
        }
      }).catch((e) => {
        this.$message.warn('刷新缓存失败！');
        console.log('刷新失败', e);
      });
    }
  },
  watch: {
    openKeys(val) {
      console.log('openKeys', val);
    },
  },
};
</script>
<style scoped>

/*列表上方操作按钮区域*/
.ant-card-body .table-operator {
  margin-bottom: 8px;
}
/** Button按钮间距 */
.table-operator .ant-btn {
  margin: 0 8px 8px 0;
}
.table-operator .ant-btn-group .ant-btn {
  margin: 0;
}

.table-operator .ant-btn-group .ant-btn:last-child {
  margin: 0 8px 8px 0;
}
/*列表td的padding设置 可以控制列表大小*/
.ant-table-tbody .ant-table-row td {
  padding-top: 15px;
  padding-bottom: 15px;
}

/*列表页面弹出modal*/
.ant-modal-cust-warp {
  height: 100%
}

/*弹出modal Y轴滚动条*/
.ant-modal-cust-warp .ant-modal-body {
  height: calc(100% - 110px) !important;
  overflow-y: auto
}

/*弹出modal 先有content后有body 故滚动条控制在body上*/
.ant-modal-cust-warp .ant-modal-content {
  height: 90% !important;
  overflow-y: hidden
}
/*列表中有图片的加这个样式 参考用户管理*/
.anty-img-wrap {
  height: 25px;
  position: relative;
}
.anty-img-wrap > img {
  max-height: 100%;
}
/*列表中范围查询样式*/
.query-group-cust{width: calc(50% - 10px)}
.query-group-split-cust:before{content:"~";width: 20px;display: inline-block;text-align: center}

/*erp风格子表外框padding设置*/
.ant-card-wider-padding.cust-erp-sub-tab>.ant-card-body{padding:5px 12px}

/* 内嵌子表背景颜色 */
.j-inner-table-wrapper /deep/ .ant-table-expanded-row .ant-table-wrapper .ant-table-tbody .ant-table-row {
  background-color: #FFFFFF;
}

/**隐藏样式-modal确定按钮 */
.jee-hidden{display: none}
</style>
